import React from 'react';
import './style.css';
import { Container, Section, Heading, TextWrapper } from '../../globalStyles';

import LayoutImage from '../_library/lay_img/script';

const Statement = () => {
  return (
    <Section padding="4rem 0" smPadding="2em 0.7rem" colour="#252525" position="relative" id="statement">
      <Container>
        <div className="statement__container">
          <div className="statement__grid">
            <div className="statement__row">
              <div className="statement__column vision__banner">
                <LayoutImage img="./assets/vision.jpg" alt="Banner: vision" width={256} inverse />
              </div>
              <div className="statement__column mission__content">
                <Heading align="left">Mission</Heading>
                <br />
                <TextWrapper align="left" >
                  <ul>
                    Our mission is to create, strengthen & grow the people in<span> a loving relationship with Jesus Christ...</span>
                    <li>By proclaiming the love of Jesus Christ & to heal the broken-hearted by conducting Evangelistic crusades, healing & deliverance meetings.</li>
                    <li>By equipping disciples and five-fold ministry leaders by conducting seminars & short-term Bible, discipleship training courses.</li>
                  </ul>
                </TextWrapper>
              </div>
            </div>
            <div className="statement__row">
              <div className="statement__column vision__content">
                <Heading align="right" >Vision</Heading>
                <br />
                <TextWrapper align="left" >
                  <ul>
                    Comforter Ministries International seeks people to <span>"to know Christ & to make him known."</span> This is done in two ways...
                    <li>To share the transforming love of Jesus Christ and to reach the unreached with the truth of the Gospel by presenting it with love & compassion, and with the powerful demonstration of the power of the Holy Spirit through signs and wonders.</li>
                    <li>To create and equip disciples and five-fold ministry leaders to transform their natives & neighbours, and to prepare them for the Second coming of Jesus Christ.</li>
                  </ul>
                </TextWrapper>
              </div>
              <div className="statement__column mission__banner">
                <LayoutImage img="./assets/mission.jpg" alt="Banner: mission" width={256} />
              </div>
            </div>
          </div>
          <div className="center__cross">
            <div className="vertical__line line__center"></div>
            <div className="horizontal__line line__center"></div>
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default Statement;