import React, { useState } from 'react';
import './style.css';
import { Container, Section, Heading, TextWrapper } from '../../globalStyles';

import LayoutImage from '../_library/lay_img/script';

const About = () => {
  const [readMore, setReadMore] = useState(false);

  const linkName = readMore ? 'READ LESS ▲' : 'READ MORE ▼';
  return (
    <Section padding="4rem 0" smPadding="2rem 0.7rem" position="relative" inverse id="about">
      <Container>
        <div className="about__container">
          <div className="about__mob_head">
            <Heading align="right" inverse >About</Heading>
          </div>
          <div className="about__grid">
            <div className="about_column">
              <div className="about__banner">
                <div className="decor-1"></div>
                <div className="decor-2"></div>
                <LayoutImage img="./assets/about-2.jpg" alt="Banner: About" width={500} />
              </div>
              <div className="about__story">
                <TextWrapper align="left" >
                  <h3>"I am what I am but by the grace of God".</h3><br /><span>Yes.</span> It is His grace that I am not consumed. This word of God is true in my life. Indeed, God is good, and His mercies endure forever. Praise the Lord!
                  <br /><br />I was born and raised in a Christian family according to Christian faith and principles. My mother and father were devout Catholics. They have positioned themselves as exemplary Christians who give and live generously. They had always shown compassion for the hungry and the impoverished, establishing themselves as excellent examples of Christian charity. I imitate their values. They sacrificed much to provide us with a good education and food. I have three siblings. My parents embody love in all its forms. They adhered to Christian principles and values.<br /><br />
                  My paternal uncle was running a charismatic centre where people came to attend retreats and be filled with the Holy Spirit.
                  {/* <h2><br />That was how my ministry started.</h2> */}
                </TextWrapper>
              </div>
            </div>
            <div className="about_column about__ext">
              <Heading align="right" inverse >About</Heading>
              <div className="about__image">
                <LayoutImage img="./assets/about.jpg" alt="Dominic Antony" width={450} />
              </div>
            </div>
          </div>
          <div className="extra__content">
            {readMore && extraContent}
            <div className="read-more-link">
              <a onClick={() => { setReadMore(!readMore) }}><h2>{linkName}</h2></a>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default About;

export const extraContent = <div>
  <TextWrapper align="left" >
    My paternal grandmother was constantly seeking the face of God and praying for people who asked her for prayers. She would pray with great love and compassion. Many people thronged for prayers, healing, and deliverance. Her heart would go out to those in need of prayers.<br /><br />
    When I was a teenager, I was always hanging out with my friends. My mother was heartbroken. Even though I was surrounded by numerous friends, I continued to feel empty inside.<br /><br />
    My mother once made me sit with her while she spoke to me about the love of God. I became aware of my need for a Savior. When I reached a crossroads in my life, she urged me to turn to Jesus Christ. In 1997, I accepted Jesus Christ as my personal Lord and Savior.<br /><br />
    Following this incident, one of my aunts, who came from the US, handed me a Holy Bible. I began to read the Bible with great passion. It became a source of joy and strength. My joy knew no bounds. I longed to share my testimony among the youth.<br /><br />
    I began personal evangelism among the same group of friends I used to be with during my early teens. I began to model my private prayer life following the example of my paternal grandmother. As a result, I grew in my personal relationship with God.<br /><br />
    I developed a great passion to equip myself through a discipleship training program. I once encountered Jesus in a unique way while I was in class. He was standing in front of me. He repeatedly asked me four times: <span>"Do you love me, Dominic?" </span> Tears were streaming down from my eyes upon hearing His voice. On the fourth time, I responded to Him by saying, <span>"I do." </span> I saw Jesus holding His heart in the palm of His hand. As He stretched His hand towards me, I saw that heart disappear from His hand and literally felt it thrusting and penetrating through my chest. From that day on, I had tremendous burden for souls. "Souls, souls, souls!" my heart cried.<br /><br />
    He said, <span>"If you love me, share my love with others." </span>At this point, I began to share the love of Christ with others.<br /><br />
    In 1999, the Lord gave my ministry the name,<span> 'Comforter Ministries'.</span><br /><br />
    My vision is to reach the unreached with the Gospel. I am proclaiming the saving power of Jesus Christ among the unreached, the poor, and the destitute.<br /><br />
    Over the years I have encountered several black magicians, witch doctors and alcoholics. By God’s grace, many have been won for Christ. Several broken marriages were restored, and 9 dead were raised to life. Many people were healed of HIV, AIDS, cancer, and barrenness. There are many testimonies of blind seeing, deaf hearing and bent back straightened. Praise be to His Holy Name.<br /><br />
    <span>Wherever the Holy Spirit leads me, Good News is preached!
      People turn from darkness to light!
      Backslidden return to God! Demons flee!
      Sickness is healed!
      Souls get added to the Kingdom of God!
      And the Name of the Lord is glorified!<br /><br /></span>
    Our God is a faithful God. He will fulfil whatever He promised. Yes, He is a promise keeping God. He opened doors for me to preach the Gospel in different parts of India, Nepal, Bhutan, South Korea, and Japan.<br /><br />
    God enabled me to lead many temple priests and Buddhist monks to Salvation through the blood of Jesus.<br /><br />
    Even though I faced so many challenges in taking the Gospel to underground Churches in our neighboring countries, I reaped a great harvest of Souls there.<br /><br />
    I am blessed with a wife who shares the same passion and vision. We together lift the name of the Lord high.<br /><br />
    It was God who called me by my name and set me apart for this great ministry. He chose me; I never chose Him. He is using me in His ministry.<br /><br />
    <h3>We'll continue to reach the people wherever we go with the saving power of the Gospel as long as we live with the constraining love of Christ (2 Cor 5:14).</h3><br />
    <h3>To God be the GLORY!</h3><br />
  </TextWrapper>
</div>;