import React from "react";
import { Section, Container } from "../../globalStyles";
import './style.css';
// import FooterContent from "../footer-content/script";
// import FooterCTA from "../footer-cta/script";
import { NavMenu, NavItem, NavLinks, NavLogo, NavIcon } from "../Navbar/NavbarStyle";
import { useLocation, useNavigate } from 'react-router-dom';
import { data } from '../../data/NavbarData';

const Footer = () => {

  let navigate = useNavigate();
  let location = useLocation();

  const scrollTo = (id) => {
    const element = document.getElementById(id);

    element.scrollIntoView({
      behavior: 'smooth',
    });
  }

  const nav2section = (to, id) => {
    if (id && location.pathname === '/') {
      scrollTo(id);
    }

    navigate(to);
  };

  return (
    <Section padding="4rem 0 2rem 0" id="connect">
      <Container>
        <div className="footer__container">
          <div className="footer__content">
            <div className="footer__head">
              <NavLogo to='/'>
                <NavIcon src="./assets/logo.svg" alt="logo" />
                Comforter Ministries<br />International
              </NavLogo>
              <div className="footer__seperator"></div>
              <div className="footer__address">
                140, Mettu street, Chengalpattu, PIN - 603002, Tamilnadu, India
              </div>
              <div className="footer__contact">
                <div className="contact__detail">
                  <span>e-mail: </span>
                  <a href="mailto:connect@cmintl.org" target="_blank" rel="noopener noreferrer">connect@cmintl.org</a>
                </div>
                <div className="contact__detail">
                  <span>phone/whatsapp: </span>
                  <a href="https://wa.me/919789819800" target="_blank" rel="noopener noreferrer">+91 9789819800</a>
                </div>
              </div>
            </div>
            <div className="footer__menu">
              <NavMenu>
                <NavItem key={0}>
                  <NavLinks onClick={() => { nav2section("/", "#") }} >Home</NavLinks>
                </NavItem>
                {data.map((el, index) => (
                  el.text !== "Connect" ?
                    (<NavItem key={index}>
                      <NavLinks onClick={() => { nav2section(el.to, el.id) }} >{el.text}</NavLinks>
                    </NavItem>) : null
                ))}
              </NavMenu>
            </div>
          </div>

          <div className="footer__credits">
            {/* <div className="footer__copy">cmintl.org © 2022</div> */}
            <div className="dev__promo">
              {/* <a href="http://madscribbles.design" target="_blank" rel="noreferrer">Designed by MAD SCRIBBLES DESIGN STUDIO</a> */}
              Designed by MAD SCRIBBLES DESIGN STUDIO
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
}

export default Footer;